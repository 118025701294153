$(document).ready(function () {

    $('.asr2021-menu-chapters').hover(function () {
        $('.asr2021-menu-open-grey').show();
        $('.asr2021-menu-downloads').dropdown('hide')
    });

    $('.asr2021-mobile-menu').click(function () {
        $('.asr2021-ghost-btn').toggleClass('mobile');
        $('.asr2021-menu-open').toggle();
        $('.asr2021-menu-open-items-wrapper').find('a').each(function () {
            if (typeof $(this).attr('data-target') != 'undefined' && $(this).attr('data-target').length > 0) {
                old_href = $(this).attr('href');
                $(this).attr('old-href', old_href);
                $(this).attr('href', '#')
            }
        })
    });
    $('.asr2021-menu-open').mouseleave(function (e) {
        //$('.overlay-2, .overlay-3').css({'opacity' : 0, 'visibility' : 'hidden', 'display' : 'none' });
        $('.overlay-2, .overlay-3').removeClass('open');
        $(this).removeClass('menu-open');
    });
    $('.asr2021-menu-open-items-wrapper').on('mouseenter', 'a', function () {
        var section_title = $(this).text();

        $('.section_title').text(section_title);

        data_target = $(this).attr('data-target');
        $('.asr2021-menu-level-2, .asr2021-menu-level-3').hide();
        $('.l2-' + data_target).show();
        $('.l2-' + data_target).show();
        //$('.overlay-3').css({'opacity': 0, 'visibility': 'hidden', 'display' : 'block'});
        $('.overlay-3').removeClass('open');

        if($(this).hasClass('asr2021-menu-mobile-no-next-item')){
          //  $('.overlay-2').css({'opacity': 0, 'visibility': 'hidden', 'display' : 'none' });
            $('.overlay-2').removeClass('open');
        } else {
        //    $('.overlay-2').css({'opacity' : 1, 'visibility' : 'visible', 'display' : 'block' });
            $('.overlay-2').addClass('open');
            $('.asr2021-menu-open').addClass('menu-open');
        }
    });
    $('.asr2021-menu-level-2').on('mouseenter', 'a', function () {
        data_target = $(this).attr('data-target');
        if (data_target !== null && data_target !== undefined) {
            $('.asr2021-menu-level-3').hide();
            $('.l3-' + data_target).show();

            if(!$(this).parent().hasClass('asr2021-menu-no-l3')){
            //    $('.overlay-3').css({'opacity' : 1, 'visibility' : 'visible', 'display' : 'block'});
                $('.overlay-3').addClass('open');
            } else {
          //      $('.overlay-3').css({'opacity' : 0, 'visibility' : 'hidden', 'display' : 'block'});
                $('.overlay-3').removeClass('open');
            }
        }
    });
    $('.asr2021-menu-downloads').hover(function () {
        $('.asr2021-menu-downloads').dropdown('show');
        $('.asr2021-menu-open').hide()
    });
    $('.asr2021-downloads-menu').mouseleave(function (e) {
        $('.asr2021-menu-downloads').dropdown('hide')
    })
});
$('.asr2021-menu-open-items-wrapper > a, .asr2021-menu-level-2 > a, .asr2021-menu-level-3 > a, .asr2021-menu-mobile-items-wrapper > a').click(function (e) {
    $(location).attr('href', $(this).attr('href'))
});
$('.asr2021-menu-open-items-wrapper').on('click', 'a', function (e) {
    data_target = $(this).attr('data-target');
    if ($('.asr2021-menu-mobile-items-wrapper').css('display') == 'none' || $('.asr2021-menu-mobile-items-wrapper').css('visibility') == 'hidden') {
        $(location).attr('href', $(this).attr('href'))
    } else {
        $('.asr2021-menu-open-items-wrapper').hide();
        $('.asr2021-menu-mobile-items-wrapper').html('');
        $('.asr2021-menu-mobile-items-wrapper').append($('<a href="#" class="asr2021-mobile-backbtn" data-target="back">Back</a>'));
        $('.asr2021-menu-mobile-items-wrapper').append($('<span>Read everything</span>'));
        $('.asr2021-menu-mobile-items-wrapper').append($('<a href="' + $(this).attr('old-href') + '" class="asr2021-mobile-btn asr2021-menu-mobile-no-next-item">' + $(this).text() + '</a>'));
        if (typeof $(this).attr('data-target') != 'undefined' && $(this).attr('data-target').length > 0) {
            $('.asr2021-menu-mobile-items-wrapper').append($('<span>Or read by chapter</span>'));
            $('.l2-' + data_target).each(function () {
                $('.asr2021-menu-mobile-items-wrapper').append('<div class="asr2021-mobile-levels">' + $(this).html() + '</div>')
            });
            $('.asr2021-mobile-levels').find('a').each(function () {
                l3_data_target = $(this).attr('data-target');
                l3_contents = $('.l3-' + l3_data_target).html();
                if (typeof l3_contents != 'undefined' && l3_contents.length > 0) {
                    $('<div class="asr2021-mobile-l3">' + l3_contents + '</div').insertAfter($(this))
                }
            })
        } else {
            $(location).attr('href', $(this).attr('href'))
        }
    }
});
$('.asr2021-menu-mobile-items-wrapper').on('click', 'a', function () {
    data_target = $(this).attr('data-target');
    if (data_target === 'back') {
        $('.asr2021-menu-mobile-items-wrapper').html('');
        $('.asr2021-menu-open-items-wrapper').show()
    }
});
